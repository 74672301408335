/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import e from "../Error.js";
import t from "../Logger.js";
import { removeMaybe as o } from "../maybe.js";
import { onAbortOrThrow as s, isPromiseLike as r, isAbortError as n } from "../promiseUtils.js";
import { staticWorkerMessages as a } from "./staticWorkerMessages.js";
import { newJobId as i, receiveMessage as l, toInvokeError as d, postMessage as c, MessageType as h } from "./utils.js";
import { createWorker as p } from "./workerFactory.js";
const {
  ABORT: b,
  INVOKE: m,
  OPEN: _,
  OPENED: g,
  RESPONSE: u
} = h;
class j {
  static async create(e) {
    const t = await p();
    return new j(t, e);
  }
  constructor(e, o) {
    this._outJobs = new Map(), this._inJobs = new Map(), this.worker = e, this.id = o, e.addEventListener("message", this._onMessage.bind(this)), e.addEventListener("error", e => {
      e.preventDefault(), t.getLogger("esri.core.workers.WorkerOwner").error(e);
    });
  }
  terminate() {
    this.worker.terminate();
  }
  async open(e, t = {}) {
    const {
        signal: o
      } = t,
      r = i();
    return new Promise((t, n) => {
      const a = {
        resolve: t,
        reject: n,
        abortHandle: s(o, () => {
          this._outJobs.delete(r), this._post({
            type: b,
            jobId: r
          });
        })
      };
      this._outJobs.set(r, a), this._post({
        type: _,
        jobId: r,
        modulePath: e
      });
    });
  }
  _onMessage(e) {
    const t = l(e);
    if (t) switch (t.type) {
      case g:
        this._onOpenedMessage(t);
        break;
      case u:
        this._onResponseMessage(t);
        break;
      case b:
        this._onAbortMessage(t);
        break;
      case m:
        this._onInvokeMessage(t);
    }
  }
  _onAbortMessage(e) {
    const t = this._inJobs,
      o = e.jobId,
      s = t.get(o);
    s && (s.controller && s.controller.abort(), t.delete(o));
  }
  _onInvokeMessage(e) {
    const {
        methodName: t,
        jobId: o,
        data: s,
        abortable: i
      } = e,
      l = i ? new AbortController() : null,
      c = this._inJobs,
      h = a[t];
    let p;
    try {
      if ("function" != typeof h) throw new TypeError(`${t} is not a function`);
      p = h.call(null, s, {
        signal: l ? l.signal : null
      });
    } catch (b) {
      return void this._post({
        type: u,
        jobId: o,
        error: d(b)
      });
    }
    r(p) ? (c.set(o, {
      controller: l,
      promise: p
    }), p.then(e => {
      c.has(o) && (c.delete(o), this._post({
        type: u,
        jobId: o
      }, e));
    }, e => {
      c.has(o) && (c.delete(o), e || (e = {
        message: "Error encountered at method" + t
      }), n(e) || this._post({
        type: u,
        jobId: o,
        error: d(e || {
          message: `Error encountered at method ${t}`
        })
      }));
    })) : this._post({
      type: u,
      jobId: o
    }, p);
  }
  _onOpenedMessage(e) {
    const {
        jobId: t,
        data: s
      } = e,
      r = this._outJobs.get(t);
    r && (this._outJobs.delete(t), o(r.abortHandle), r.resolve(s));
  }
  _onResponseMessage(t) {
    const {
        jobId: s,
        error: r,
        data: n
      } = t,
      a = this._outJobs.get(s);
    a && (this._outJobs.delete(s), o(a.abortHandle), r ? a.reject(e.fromJSON(JSON.parse(r))) : a.resolve(n));
  }
  _post(e, t, o) {
    return c(this.worker, e, t, o);
  }
}
export { j as default };