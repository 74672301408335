/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { nextTick as e } from "../nextTick.js";
import s from "./RemoteClient.js";
import { MessageType as r, receiveMessage as t } from "./utils.js";
class n {
  constructor() {
    const e = document.createDocumentFragment();
    ["addEventListener", "dispatchEvent", "removeEventListener"].forEach(s => {
      this[s] = (...r) => e[s](...r);
    });
  }
}
class a {
  constructor() {
    this._dispatcher = new n(), this._workerPostMessage({
      type: r.HANDSHAKE
    });
  }
  terminate() {}
  get onmessage() {
    return this._onmessageHandler;
  }
  set onmessage(e) {
    this._onmessageHandler && this.removeEventListener("message", this._onmessageHandler), this._onmessageHandler = e, e && this.addEventListener("message", e);
  }
  get onmessageerror() {
    return this._onmessageerrorHandler;
  }
  set onmessageerror(e) {
    this._onmessageerrorHandler && this.removeEventListener("messageerror", this._onmessageerrorHandler), this._onmessageerrorHandler = e, e && this.addEventListener("messageerror", e);
  }
  get onerror() {
    return this._onerrorHandler;
  }
  set onerror(e) {
    this._onerrorHandler && this.removeEventListener("error", this._onerrorHandler), this._onerrorHandler = e, e && this.addEventListener("error", e);
  }
  postMessage(s) {
    e(() => {
      this._workerMessageHandler(new MessageEvent("message", {
        data: s
      }));
    });
  }
  dispatchEvent(e) {
    return this._dispatcher.dispatchEvent(e);
  }
  addEventListener(e, s, r) {
    this._dispatcher.addEventListener(e, s, r);
  }
  removeEventListener(e, s, r) {
    this._dispatcher.removeEventListener(e, s, r);
  }
  _workerPostMessage(s) {
    e(() => {
      this.dispatchEvent(new MessageEvent("message", {
        data: s
      }));
    });
  }
  async _workerMessageHandler(e) {
    const n = t(e);
    if (n && n.type === r.OPEN) {
      const {
        modulePath: e,
        jobId: t
      } = n;
      let a = await s.loadWorker(e);
      a || (a = await import( /* @vite-ignore */ /* webpackIgnore: true */e));
      const o = s.connect(a);
      this._workerPostMessage({
        type: r.OPENED,
        jobId: t,
        data: o
      });
    }
  }
}
export { a as default };