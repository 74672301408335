/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import r from "../Error.js";
import has from "../has.js";
import { throwIfAborted as t } from "../promiseUtils.js";
import e from "./Connection.js";
import { register as o } from "./connectionRegistry.js";
import n from "./RemoteClient.js";
import i from "./WorkerOwner.js";
const s = has("host-browser") ? Math.min(navigator.hardwareConcurrency - 1, has("workers-pool-size") ?? 8) : 0;
let a = has("esri-mobile") ? Math.min(s, 3) : s;
a || (a = has("safari") && has("mac") ? 7 : 2);
let c = 0;
const l = [];
function m() {
  g();
}
function u(r, t) {
  return f(r, {
    client: t
  });
}
async function f(r, t) {
  const n = new e(),
    {
      registryTarget: i,
      ...s
    } = t;
  return await n.open(r, s), i && o(i, n), n;
}
async function p(e, o = {}) {
  if ("string" != typeof e) throw new r("workers:undefined-module", "modulePath is missing");
  let i = o.strategy || "distributed";
  if (has("host-webworker") && !has("esri-workers") && (i = "local"), "local" === i) {
    let r = await n.loadWorker(e);
    r || (r = await import( /* @vite-ignore */ /* webpackIgnore: true */e)), t(o.signal);
    const i = o.client || r;
    return f([n.connect(r, o.schedule)], {
      ...o,
      client: i
    });
  }
  if (await g(), t(o.signal), "dedicated" === i) {
    const r = c++ % a;
    return f([await l[r].open(e, o)], o);
  }
  if (o.maxNumWorkers && o.maxNumWorkers > 0) {
    const r = Math.min(o.maxNumWorkers, a);
    if (r < a) {
      const t = new Array(r);
      for (let n = 0; n < r; ++n) {
        const r = c++ % a;
        t[n] = l[r].open(e, o);
      }
      return f(t, o);
    }
  }
  return f(l.map(r => r.open(e, o)), o);
}
function w() {
  d && (h.abort(), d = null);
  for (let r = 0; r < l.length; r++) l[r] && l[r].terminate();
  l.length = 0;
}
let h,
  d = null;
async function g() {
  if (d) return d;
  h = new AbortController();
  const r = [];
  for (let t = 0; t < a; t++) {
    const e = i.create(t).then(r => (l[t] = r, r));
    r.push(e);
  }
  return d = Promise.all(r), d;
}
export { e as Connection, n as RemoteClient, m as initialize, p as open, u as openWithPorts, w as terminate };